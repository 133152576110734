.inquiry-form {
  .form {
    display: grid;
    gap: $spacing-14;

    @include mq-min-width($bp-grid) {
      //grid-column-gap: $spacing-14;
      grid-template-columns: 1fr 1fr 1fr 1fr;

      .form-field[id='form-field-salutation'] {
        grid-column: span 2;
      }

      .form-field[id='form-field-firstname'] {
        grid-row: 2;
        grid-column: span 2;
      }

      .form-field[id='form-field-surname'] {
        grid-row: 2;
        grid-column: span 2;
      }

      .form-field[id='form-field-phoneNum'] {
        grid-row: 3;
        grid-column: span 2;
      }

      .form-field[id='form-field-email'] {
        grid-row: 3;
        grid-column: span 2;
      }

      .form-field[id='form-field-message'] {
        grid-row: 4/5;
        grid-column: span 4;
      }

      .form-field[id='form-field-street'] {
        grid-row: 6;
        grid-column: span 3;
      }

      .form-field[id='form-field-houseNum'] {
        grid-row: 6;
        grid-column: span 1;
      }

      .form-field[id='form-field-plz'] {
        grid-row: 7;
        grid-column: span 1;
      }

      .form-field[id='form-field-city'] {
        grid-row: 7;
        grid-column: span 3;
      }

      .footnote {
        grid-row: 8;
        grid-column: span 4;
      }

      .buttons-container {
        grid-row: 9;
        //grid-column: span 4;
      }
    }

    .mat-raised-button {
      @include cta();
    }

    .mat-button-wrapper {
      font-family: Asap, Arial, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
    }

    .mat-error {
      font-size: 16px;
    }

    .footnote {
      color: $dwg-color-text-light;
      font-size: 16px;
    }
  }
}
