.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $layer-top;

  background-color: $dwg-color-background-semi-dark;
}

.modal-container {
  display: flex;
  flex-direction: column;
  min-width: 50vw;
  max-width: 90vw;
  min-height: 50vh;
  max-height: 90vh;
  padding: $spacing-10;

  background-color: $dwg-white;
  border-radius: 20px;

  .close-button {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    width: 25px;
    height: 25px;
    margin-bottom: $spacing-10;
    margin-left: auto;

    cursor: pointer;

    svg {
      position: relative;
      width: 100%;
      height: 100%;
      fill: currentColor;
    }
  }
}
