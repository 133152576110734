/* stylelint-disable property-no-vendor-prefix, value-no-vendor-prefix */
/// Line clamp for single paragraphs
@mixin line-clamp($lines) {
  display: -webkit-box;
  overflow: hidden;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
}
/* stylelint-enable */

/// Hide from view, but keep it accessible for assistive technologies
@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;

  border: 0;

  white-space: nowrap;

  user-select: none;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
}
