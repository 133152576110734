.checklist-card {
  @include vertical-image-card();
}

.checklist-card-image {
  height: clamp(170px, 30vw, 450px);
}

.checklist-card-heading {
  @include responsive-layout-property(margin, $spacing-24 0, $spacing-40 0);
  @include responsive-layout-property(padding, 0 $spacing-24, 0 $spacing-62);

  text-align: center;
}

.checklist-card-text {
  @include responsive-layout-property(padding, 0 $spacing-24 $spacing-40, 0 $spacing-62 $spacing-62);
}
