@import 'mixins/breakpoints';
@import 'mixins/utilities';
@import 'mixins/grid';
@import 'mixins/typography';
@import 'mixins/elements';

/// Resets UA styles for button element
@mixin reset-button-styles() {
  padding: 0;

  background-color: transparent;
  border-width: 0;
  outline: none;

  font-family: inherit;

  appearance: none;
}

@mixin content-section() {
  margin: $spacing-40 0;
}

@mixin responsive-layout-property($property, $value-small, $value-big) {
  #{$property}: #{$value-small};

  @include mq-min-width($bp-layout-scale) {
    #{$property}: #{$value-big};
  }
}
