.text-image-image-container {
  margin-bottom: $spacing-40;
}

.text-image-image {
  display: block;
  width: 60%;
  margin: 0 auto;
}

@include mq-min-width($bp-grid) {
  .text-image {
    display: grid;
    gap: 0 var(--main-grid-gap);
    grid-template-columns: 5fr 7fr;
  }

  .text-image-image-container {
    align-self: center;
    margin-bottom: 0;
    justify-self: center;
  }

  .text-image-image {
    width: auto;
  }

  .text-image-heading {
    margin-top: 0;
  }
}
