@font-face {
  font-family: Asap;
  src: url('../../assets/fonts/Asap/Asap-Regular.ttf');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: Asap;
  src: url('../../assets/fonts/Asap/Asap-SemiBold.ttf');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Asap;
  src: url('../../assets/fonts/Asap/Asap-Bold.ttf');
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}
