.team-member-list {
  display: grid;
  margin: 0;
  padding: 0;
  gap: var(--main-grid-gap);

  list-style-type: none;

  grid-template-columns: 1fr;

  @include mq-min-width($bp-layout-scale) {
    grid-template-columns: 1fr 1fr;
  }
}

.team-member-entry {
  position: relative;
  height: 260px;
}

.team-member {
  $member: &;

  display: grid;
  grid-template-rows: auto;

  transform-style: preserve-3d;

  transition: transform 0.5s ease-in-out;
  grid-template-columns: 1fr;

  &-front,
  &-back {
    display: block;
    grid-row: 1;
    grid-column: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;

    background-color: $dwg-white;
    border-radius: $border-radius-shadow-container;

    transform-style: preserve-3d;
    backface-visibility: hidden;
  }

  &-back {
    @include card();

    width: 100%;

    transform: rotateY(180deg);
  }

  &-front {
    display: grid;
    align-items: center;
    grid-row: 1;
    grid-template-columns: 1fr minmax(min-content, 1fr);
  }

  &.is-flipped {
    transform: rotateY(180deg);
  }
}

.team-member-image {
  grid-row: 1;
  grid-column: 1 / -1;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.team-member-name {
  @include heading-2();

  grid-row: 1;
  grid-column: 2;
  position: relative;
  z-index: 2;
  width: 100%;
  margin: auto;
  padding: $spacing-14;
  padding-left: 0;

  color: $dwg-white;
  text-align: center;
}

.team-member-contact {
  position: relative;
  height: 100%;

  ::ng-deep .contact-links {
    display: block;
  }
}
