@charset "utf-8";

// 1. abstracts
@import 'scss/abstracts/functions';
@import 'scss/abstracts/variables';
@import 'scss/abstracts/mixins';
@import 'scss/abstracts/theme-color';

// 3. base
@import 'scss/base/fonts';
@import 'scss/base/variables';
@import 'scss/base/resets';
@import 'scss/base/typography';

// 4. layout
@import 'scss/layout/layout';

// 5. components
@import 'scss/components/advantage-list';
@import 'scss/components/application-form';
@import 'scss/components/background-section';
@import 'scss/components/breadcrumb';
@import 'scss/components/cards';
@import 'scss/components/checklist';
@import 'scss/components/checklist-card';
@import 'scss/components/form-fields';
@import 'scss/components/hero';
@import 'scss/components/info-box';
@import 'scss/components/inquiry-form';
@import 'scss/components/modal';
@import 'scss/components/quote';
@import 'scss/components/real-estate-teaser-slider';
@import 'scss/components/real-estate-data';
@import 'scss/components/repair-form';
@import 'scss/components/sell-real-estate-card';
@import 'scss/components/slider';
@import 'scss/components/team-member-list';
@import 'scss/components/testimonial-slider';
@import 'scss/components/text-image';
@import 'scss/components/snackbar';

// 6. pages
//@import 'scss/pages/'

// hacks, workarounds, etc.
@import 'scss/shame';
@import 'material-icons/iconfont/material-icons.css';

// shared libs
@import 'module';

ed-clients-nettrek-google-map {
  .consent-message {
    color: $dwg-color-neutral;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  .consent-button {
    max-width: 320px;
    padding: 20px 30px;

    background-color: $dwg-color-primary;
    border: 0;
    border-radius: 8px;

    color: $dwg-white;
    font-size: 16px;
    text-transform: uppercase;

    cursor: pointer;
  }
}

.consent-actions {
  button {
    max-width: 320px;
    padding: 20px 30px;

    background-color: $dwg-color-primary;
    border: 0;
    border-radius: 8px;

    color: $dwg-white;
    font-size: 16px;
    text-transform: uppercase;

    cursor: pointer;
  }

  @media(max-width: $bp-sm) {
    button {
      /* stylelint-disable-next-line declaration-no-important */
      font-size: 14px !important;
    }
  }
}

dwg-shorthand-icons {
  position: fixed;
  top: 30%;
  right: 0;
  z-index: $layer-shorthands;

  @include mq-max-width($bp-grid-lg) {
    display: none;
  }
}
