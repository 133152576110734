/// Shortcut for min-width media query
@mixin mq-min-width($breakpoint) {
  @media screen and (min-width: #{$breakpoint}) {
    @content;
  }
}

/// Shortcut for max-width media query
@mixin mq-max-width($breakpoint) {
  @media screen and (max-width: #{$breakpoint - 1px}) {
    @content;
  }
}
