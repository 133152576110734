@import '../../scss/component';

$news-slider-control-size: 40px;
$news-slider-control-spacing: $news-slider-control-size + $spacing-20;

.slider {
  display: grid;
  align-items: center;
  grid-template-rows: min-content min-content;
  gap: $spacing-20;
  grid-template-columns: 1fr 1fr;

  @include mq-min-width($bp-sm) {
    grid-template-rows: auto;
    grid-template-columns: minmax($news-slider-control-spacing, auto) 1fr minmax($news-slider-control-spacing, auto);

    > *,
    &.news-slider .news-slides {
      grid-row: 1 / -1;
      grid-column: auto;
    }
  }

  > * {
    grid-row: 1 / -1;
  }

  .news-slides {
    grid-row: 1 / 1;
    grid-column: 1 / -1;
  }
}

.slider-controls {
  display: contents;
}

.slider-control {
  grid-row: 2;
  width: $news-slider-control-size;
  height: $news-slider-control-size;

  color: $dwg-color-interaction-primary;

  cursor: pointer;

  @include mq-min-width($bp-sm) {
    grid-row: 1;
    grid-column: auto;
  }

  &-icon {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }

  &--previous {
    //grid-column: 1;
  }

  &--next {
    //grid-column: 2;
    margin-left: auto;
  }
}
