.info-box {
  @include card();
  @include responsive-layout-property(
    padding,
    $spacing-20 $spacing-14,
    $spacing-40 $spacing-32
  );

  display: grid;
  grid-template-areas:
    'notice-image notice-close'
    'notice-title notice-title'
    'notice-content notice-content';

  &::before {
    content: '';

    display: block;
    grid-row: 1;
    grid-column: 1 / -1;
    margin: -$spacing-20 -$spacing-14;
    margin-bottom: 0;

    background-color: $dwg-color-background-light;
  }
}

.info-box-image-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-area: notice-image;
  width: auto;
  height: 80px;

  background-color: $dwg-color-background-light;
}

.info-box-image {
  width: 66px;
  height: auto;
}

.info-box-close-button {
  @include reset-button-styles();
  @include copy-link();

  align-self: center;
  grid-area: notice-close;

  cursor: pointer;
  justify-self: end;
}

.info-box-title {
  @include heading-3();

  grid-area: notice-title;
  margin-top: $spacing-20;
}

.info-box-message {
  grid-area: notice-content;
  margin-top: 1em;
}

.info-box-phone {
  font-weight: bold;
}

@include mq-min-width($bp-layout-scale) {
  .info-box {
    grid-template:
      'notice-image notice-title notice-close' auto
      'notice-image notice-content notice-content' auto
      / auto 1fr;
    padding: $spacing-32;
    gap: 0 $spacing-40;

    &::before {
      content: none;
    }
  }

  .info-box-image-container {
    justify-content: center;
    width: 193px;
    height: auto;
    margin: -$spacing-32;
    margin-right: auto;
  }

  .info-box-image {
    width: 55%;
    height: auto;
  }

  .info-box-title {
    margin-top: 0;
  }

  .info-box-message {
    padding: 0;
  }
}
