.success-snackbar-message {
  background-color: rgb(0, 174, 0, 0.9);
}

.fail-snackbar-message {
  background-color: rgb(222, 0, 0, 0.9);
}

.mat-simple-snackbar {
  color: $dwg-color-neutral;
  font-size: 16px;
  font-weight: bold;
}
