/*
.testimonial-slider {

}
*/

.testimonials-slider-controls {
  display: flex;
  justify-content: center;
  margin-top: $spacing-32;
  gap: var(--main-grid-gap);
}

.testimonials-slider-control {
  width: 24px;
  height: 24px;

  background-color: $dwg-color-border;
  border: 6px solid $dwg-color-border;
  border-radius: 50%;

  cursor: pointer;

  &.active,
  &:active,
  &:focus,
  &:hover {
    background-color: $dwg-color-primary;
  }
}
