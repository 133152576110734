// Layout
$max-content-width: 1200px;

$grid-gap-sm: 24px;
$grid-gap-lg: 40px;

// Breakpoints
$bp-xxs: 480px;
$bp-xs: 560px;
$bp-sm: 768px;
$bp-md: 960px;
$bp-lg: 1024px;
$bp-xl: 1280px;

// Global layout breakpoints
$bp-typography: $bp-sm;
$bp-layout-scale: $bp-sm;

$bp-grid: $bp-lg;
$bp-grid-lg: $max-content-width + $grid-gap-lg;

$bp-grid-sidebar-sm: $bp-md;
$bp-grid-sidebar-md: $bp-lg;

// Colors
$dwg-color-primary: #de0000;
$dwg-color-secondary: #00ae00;
$dwg-color-neutral: #323232;
$dwg-color-neutral-light: #a6a6a6;

$dwg-color-background-dark: rgb(0, 0, 0, 0.925);
$dwg-color-background-semi-dark: rgb(38, 38, 38, 0.5);
$dwg-color-background-light: #f6f6f6;

$dwg-white: #fff;
$dwg-black: #000;

$dwg-color-border: #e1e1e1;
$dwg-color-text: $dwg-color-neutral;
$dwg-color-text-light: $dwg-color-neutral-light;

$dwg-color-interaction-primary: $dwg-color-primary;
$dwg-color-interaction-primary-active: #b80000;

$dwg-color-interaction-secondary: #323232;
$dwg-color-interaction-secondary-active: #000;

// Layers
$layer-shorthands: 80;
$layer-header: 100;
$layer-top: 9999;

// Shadows
$box-shadow-container: 0 4px 10px 0 rgba(0, 0, 0, 0.17);

// Border radius
$border-radius-shadow-container: 20px;
$border-radius-image: 30px;

// Transitions + animations
$transition-default: 0.2s ease-in-out;

// Spacings
$spacing-inset-sm: 20px;

$spacing-2: 2px;
$spacing-6: 6px;
$spacing-10: 10px;
$spacing-14: 14px;
$spacing-16: 16px;
$spacing-20: 20px;
$spacing-24: 24px;
$spacing-32: 32px;
$spacing-40: 40px;
$spacing-50: 50px;
$spacing-62: 62px;
$spacing-76: 76px;
$spacing-88: 88px;
$spacing-100: 100px;
$spacing-132: 132px;
