.sell-real-estate-card {
  @include card();

  display: flex;
  flex-direction: column;

  @include mq-min-width($bp-grid) {
    display: grid;
    grid-template-columns: auto auto;
  }
}

.sell-real-estate-card-image,
.sell-real-estate-card-text {
  @include responsive-layout-property(padding, $spacing-24, $spacing-40);
}

.sell-real-estate-card-image {
  width: calc(60px + 2 * #{$spacing-24});
  height: auto;
  padding-bottom: 0;

  @include mq-min-width($bp-layout-scale) {
    width: calc(60px + 2 * #{$spacing-40});
  }

  @include mq-min-width($bp-grid) {
    grid-row: 1;
    grid-column: 1;
    width: calc(150px + 2 * #{$spacing-40});
    padding: 2 * $spacing-24;
  }
}

.sell-real-estate-card-heading {
  @include responsive-layout-property(margin-bottom, $spacing-20, $spacing-24);

  margin-top: 0;
}

.sell-real-estate-card-text {
  @include mq-min-width($bp-grid) {
    grid-row: 1;
    grid-column: 2;
  }

  .cta {
    @include responsive-layout-property(margin-top, $spacing-20, $spacing-40);

    margin-bottom: 0;
  }
}

.sell-real-estate-card-footer {
  @include responsive-layout-property(padding, $spacing-24, $spacing-32);

  background-color: $dwg-color-background-light;

  @include mq-min-width($bp-grid) {
    grid-row: 2;
    grid-column: 1/-1;

    .checklist {
      display: flex;
      justify-content: space-around;
      gap: $spacing-20;
    }

    .checklist-item {
      margin-bottom: 0;
    }
  }
}
