.quote {
  @include heading-2();

  position: relative;
  margin: 0;

  color: $dwg-white;

  &::before {
    content: '„';

    display: block;

    color: $dwg-color-primary;
    font-size: 4em;
  }
}
