/* stylelint-disable declaration-no-important */

@use 'sass:math';
@use 'sass:list';

@mixin image() {
  display: block;
  overflow: hidden;

  border-radius: $border-radius-image;
}

@mixin page-header-image() {
  display: block;
  height: clamp(170px, 40vw, 480px);
  overflow: hidden;

  @include mq-min-width($bp-layout-scale) {
    border-radius: $border-radius-image;
  }
}

//@mixin shorthand-icons() {
//  position: relative;
//
//  dwg-shorthand-icons {
//    position: fixed;
//    top: 30%;
//    right: 0;
//    z-index: $layer-shorthands;
//
//    @include mq-max-width($bp-grid-lg) {
//      display: none;
//    }
//  }
//}

@mixin rounded-corner-image() {
  --border-radius-default-corners: #{$border-radius-image $border-radius-image $border-radius-image};
  --border-radius-bl-horizontal: max(60px, 30%);
  --border-radius-horizontal: var(--border-radius-default-corners) var(--border-radius-bl-horizontal);
  --border-radius-vertical: var(--border-radius-default-corners) 50%;

  @include image();

  border-radius: list.slash(var(--border-radius-horizontal), var(--border-radius-vertical));

  @include mq-min-width($bp-layout-scale) {
    --border-radius-bl-horizontal: 275px;
  }
}

@mixin card() {
  display: block;
  overflow: hidden;

  background-color: $dwg-white;
  border-radius: $border-radius-shadow-container;
  box-shadow: $box-shadow-container;
}

/// Card with full-width image and optional padding for the content area
@mixin vertical-image-card() {
  --vic-spacing-inset-block: var(--vic-spacing-inset-block, 20px);
  --vic-spacing-inset-inline: var(--vic-spacing-inset-inline, 20px);
  --vic-spacing-inset-top: var(--vic-spacing-inset-top, var(--vic-spacing-inset-block));
  --vic-spacing-inset: var(--vic-spacing-inset-block) var(--vic-spacing-inset-inline);
  --vic-image-min-height: var(--vic-image-min-height, 214px);

  @include card();

  padding: var(--vic-spacing-inset);

  > .image,
  .vertical-image-card-image {
    display: block;
    min-height: var(--vic-image-min-height);
    margin: calc(-1 * var(--vic-spacing-inset-block, 0)) calc(-1 * var(--vic-spacing-inset-inline, 0));
    margin-bottom: var(--vic-spacing-inset-top);
  }

  .vertical-image-card-heading {
    @include heading-3();

    margin: $spacing-24 0 $spacing-20;
  }

  .vertical-image-card-text {
    @include mq-min-width($bp-typography) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

@mixin cta() {
  display: inline-block;
  padding: 15px 11px;

  background-color: var(--cta-background-color, #{$dwg-color-interaction-primary});
  border-radius: 8px;

  color: var(--cta-text-color, #{$dwg-white});
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  cursor: pointer !important;

  transition: var(--cta-transition-property, background-color) $transition-default;

  &:hover,
  &:focus,
  &:active {
    background-color: var(--cta-background-color-active, $dwg-color-interaction-primary-active);

    color: var(--cta-text-color-active, #{$dwg-white});
  }
;
}
