@import '../../scss/component';

.real-estate-address {
  color: $dwg-color-text-light;
}

.real-estate-address-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: $spacing-6;

  vertical-align: text-top;
  fill: currentColor;
}

.real-estate-details {
  display: flex;
  justify-content: space-evenly;
  gap: 2em;

  .real-estate-detail {
    display: flex;
    flex: 33.3% 1 1;
    flex-direction: column-reverse;
  }
}

.real-estate-detail {
  text-align: left;
}

.real-estate-detail-name {
  @include copy-text-small();
  color: $dwg-color-text-light;
}

.real-estate-detail-value {
  font-size: 16px;
  font-weight: 600;

  @include mq-min-width($bp-typography) {
    font-size: 24px;
  }
}
