.checklist {
  --list-icon-color: #{$dwg-color-primary};

  margin: 0;
  padding: 0;
  list-style-image: url('data:image/svg+xml,\
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 45 34">\
  <path fill="%23de0000" fill-rule="nonzero" d="M42.67016.97799c1.63268 1.4062 1.86458 3.83445.56421 5.52042l-.13999.17169-21.41893 24.86862c-.46426.53903-1.03992.92538-1.66349 1.1543-1.29395.604-2.86231.49639-4.0816-.38941l-.17012-.13052L1.4966 20.62545c-1.73247-1.40259-1.9999-3.94406-.59731-5.67654 1.35734-1.6766 3.78128-1.98113 5.50642-.72785l.17012.13053 11.34039 9.18056L36.97806 1.40221c1.45469-1.68897 4.00313-1.8789 5.6921-.42422z" />\
  </svg>\
  ');
}

.checklist-item {
  margin-bottom: $spacing-10;
  margin-left: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}
