@import '../../scss/component';

$breadcrumb-icon-size: 20px;

.breadcrumb {
  @include copy-link();

  display: flex;
  align-items: center;
  gap: $spacing-10;

  color: $dwg-color-interaction-primary;
  text-decoration: none;
}

.breadcrumb-icon {
  width: $breadcrumb-icon-size;
  height: $breadcrumb-icon-size;
  fill: currentColor;
}
