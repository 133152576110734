html {
  font-size: 20px;
}

html,
body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.main-grid {
  @include main-grid();
}

.sidebar-grid {
  @include sidebar-grid();
}

.content-container {
  @include content-container();
}

.content-container-wide {
  @include content-container();
}

.page-header {
  margin-bottom: $spacing-62;

  @include mq-min-width($bp-layout-scale) {
    margin-bottom: $spacing-76;
  }
}

.page-header-image {
  @include page-header-image();
}

.content-section {
  @include content-section();
}

.main-content {

  //@include shorthand-icons();
  position: relative;
}
