.hero {
  --hero-end: main-end;

  &:not(.hero-fullwidth) {
    display: grid;
    gap: 0 var(--main-grid-gap);

    @include mq-min-width($bp-grid) {
      grid-column-end: var(--hero-end);
      grid-template-columns:
        minmax(calc(40% - var(--main-grid-gap)), $max-content-width * 0.5)
        minmax(50%, 1fr);
    }
  }
}

.hero-bleed {
  --hero-end: wide-end;
}

.hero-fullwidth {
  .hero-text {
    padding: 0;
  }

  .hero-heading {
    margin-bottom: $spacing-32;

    @include mq-min-width($bp-md) {
      margin-bottom: $spacing-62;
    }
  }

  .hero-image {
    height: clamp(140px, 50vw, 440px);
    margin-top: $spacing-32;

    @include mq-min-width($bp-md) {
      margin-top: $spacing-62;
    }
  }
}

.hero-cta {
  @include responsive-layout-property(margin-top, $spacing-40, $spacing-62);
}

.hero-text {
  align-self: center;
  padding: $spacing-40 0;
}

.hero-heading {
  @include responsive-layout-property(margin-bottom, $spacing-24, $spacing-40);

  margin-top: 0;
}

.hero-kicker {
  @include intro-text();
  @include responsive-layout-property(margin-bottom, $spacing-14, $spacing-32);

  color: $dwg-color-primary;
  font-weight: normal;
}

.hero-kicker,
.hero-heading {
  display: block;
}

.hero-media {
  @include responsive-layout-property(margin-bottom, $spacing-32, 0);

  order: -1;
  margin-bottom: $spacing-40;

  @include mq-min-width($bp-layout-scale) {
    order: unset;
  }
}

.hero-image {
  @include rounded-corner-image();
}
