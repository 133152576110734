@mixin copy-text() {
  font-size: 14px;
  line-height: 24px;

  @include mq-min-width($bp-typography) {
    font-size: 18px;
    line-height: 28px;
  }
}

@mixin copy-text-small() {
  font-size: 12px;
  line-height: 1.1;

  @include mq-min-width($bp-typography) {
    font-size: 16px;
  }
}

@mixin intro-text() {
  font-size: 16px;
  line-height: 21px;

  @include mq-min-width($bp-typography) {
    font-size: 20px;
    line-height: 26px;
  }
}

@mixin copy-link() {
  color: $dwg-color-interaction-primary;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

@mixin heading-1() {
  font-size: 35px;
  font-weight: bold;
  line-height: 42px;

  @include mq-min-width($bp-typography) {
    font-size: 63px;
    line-height: 62px;
  }
}

@mixin heading-2() {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;

  @include mq-min-width($bp-typography) {
    font-size: 35px;

    .kicker {
      font-size: 20px;
    }
  }

  > .kicker,
  > .heading {
    display: block;
  }

  .kicker {
    color: $dwg-color-interaction-primary;
    font-size: 18px;
  }
}

@mixin heading-3() {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;

  @include mq-min-width($bp-typography) {
    font-size: 22px;
    line-height: 28px;
  }
}
