*,
*::before,
*::after {
  box-sizing: border-box;
}

button {
  @include reset-button-styles();
}

dl[class],
dt[class],
dd[class] {
  margin: 0;
  padding: 0;
}

.visually-hidden {
  @include visually-hidden();
}
