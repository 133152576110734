@mixin main-grid() {
  display: grid;
  gap: 0;
  grid-template-columns:
    [full-start] minmax(var(--site-padding-horizontal), 1fr)
    [main-start wide-start] minmax(0, $max-content-width) [main-end wide-end]
    minmax(var(--site-padding-horizontal), 1fr) [full-end];

  @include mq-min-width($bp-grid-lg) {
    grid-template-columns:
      [full-start] var(--site-padding-horizontal)
      [wide-start] 1fr
      [main-start] minmax(0, $max-content-width) [main-end]
      1fr [wide-end]
      var(--site-padding-horizontal) [full-end];
  }
}

@mixin content-container() {
  grid-column: main-start / main-end;
}

@mixin content-container-wide() {
  grid-column: wide-start / wide-end;
}

@mixin sidebar-grid() {
  --main-content-width: var(--main-content-width, 3fr);
  --sidebar-width: var(--sidebar-width, 1fr);

  display: grid;
  grid-template-areas: 'main' 'sidebar';
  gap: var(--main-grid-gap);

  @include mq-min-width($bp-grid-sidebar-sm) {
    --main-content-width: 2fr;
    --sidebar-width: 1fr;

    grid-template-areas: 'main sidebar';
    grid-template-columns: [main-start] var(--main-content-width) [main-end sidebar-start] var(--sidebar-width) [sidebar-end];
  }

  @include mq-min-width($bp-grid-sidebar-md) {
    --main-content-width: 3fr;
    --sidebar-width: 1fr;
  }
}
