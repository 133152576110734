.application-form {

  @include mq-max-width($bp-grid) {
    .buttons-wrapper {
      margin-right: auto;
      margin-left: auto;

      text-align: center;
    }

    .buttons {
      display: inline-flex;
    }
  }

  .mat-error {
    font-size: 16px;
  }

  .mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: $dwg-color-primary;
    }
  }

  /////////////////////////////////// mat-radio
  // outer circle
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $dwg-color-primary;
  }

  // inner circle
  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: $dwg-color-primary;

    color: $dwg-color-primary;
  }

  // ripple (around circle)
  /* stylelint-disable-next-line selector-max-compound-selectors */
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    background-color: $dwg-color-primary;
  }
  ///////////////////////////////////

  .form-field-title,
  .form-field-label {
    @include heading-3();
    @include responsive-layout-property(margin-bottom, $spacing-10, $spacing-20);
    @include responsive-layout-property(margin-top, $spacing-20, $spacing-32);

    display: block;
  }

  .form-radio-group {
    display: flex;
    padding-bottom: 1.34375em; // Taken from current Material theme
    gap: $spacing-10;

    @include mq-max-width($bp-grid) {
      flex-direction: column;
    }
  }

  .form-checkbox {
    margin-bottom: $spacing-10;
  }

  .buttons {
    @include responsive-layout-property(margin-top, $spacing-50, $spacing-76);

    display: flex;
    flex-direction: column;
    gap: $spacing-20;

    @include mq-min-width($bp-typography) {
      flex-direction: row;
      gap: $spacing-32;
    }
  }
}

.application-form--personal-data {
  .form {
    @include mq-min-width($bp-grid) {
      display: grid;
      grid-column-gap: $spacing-32;
      grid-template-columns: 1fr 1fr;
    }
  }
}

.application-form--real-estate-data {
  @include mq-min-width($bp-grid) {
    .form {
      display: grid;
      grid-column-gap: $spacing-32;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .form-field,
    .form-field-title {
      grid-column: span 2;
    }

    // manual grid placements
    .form-field-title[id='form-field-title-Altstadt'],
    .form-field-title[id='form-field-title-checkDataProtection'],
    .form-field[id='form-field-checkDataProtection'] {
      grid-column: 1 / -1;
    }

    .form-field[id='form-field-minSpace'],
    .form-field[id='form-field-maxSpace'] {
      grid-column: span 1;
    }

    .form-field[id='form-field-maxSpace'] {
      align-self: end; // due to missing label
    }
  }
}
