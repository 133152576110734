.advantage-list,
.advantage {
  margin: 0;
  padding: 0;
}

.advantage-list {
  @include responsive-layout-property(
    gap,
    calc(2 * var(--main-grid-gap)) var(--main-grid-gap),
    calc(3 * var(--main-grid-gap)) var(--main-grid-gap)
  );

  display: grid;

  list-style-type: none;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.advantage-image {
  @include image();
  @include responsive-layout-property(margin-bottom, $spacing-20, $spacing-24);

  width: 100%;
  height: clamp(120px, 60vw, 280px);

  @include mq-min-width($bp-sm) {
    height: auto;
    aspect-ratio: 1.25;
  }
}

.advantage-heading {
  @include responsive-layout-property(margin-bottom, $spacing-20, $spacing-24);
}
