@import '../../scss/component';

$real-estate-teaser-slider-control-size: 70px;
$real-estate-teaser-slider-control-icon-size: 16px;

.real-estate-teaser-slider {
  position: relative;

  @include mq-min-width($bp-layout-scale) {
    &-controls {
      position: absolute;
      right: $spacing-32;
      bottom: $spacing-62;
    }

    &-control {
      color: $dwg-white;
    }
  }
}

.real-estate-teaser-slider-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $spacing-10;

  @include mq-max-width($bp-layout-scale) {
    margin: $spacing-24 auto $spacing-32;
  }
}

.real-estate-teaser-slider-control {
  @include reset-button-styles();

  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: $real-estate-teaser-slider-control-size;
  height: $real-estate-teaser-slider-control-size;

  border: 2px solid currentColor;
  border-radius: 8px;

  color: $dwg-color-text-light;

  cursor: pointer;

  /* stylelint-disable plugin/no-low-performance-animation-properties */
  transition: color $transition-default;

  @include mq-min-width($bp-layout-scale) {
    color: $dwg-white;

    &:hover,
    &:focus,
    &:active {
      color: $dwg-color-interaction-primary-active;
    }
  }

  &:hover,
  &:focus,
  &:active {
    color: $dwg-color-interaction-primary-active;
  }
}

.real-estate-teaser-slider-control-icon {
  width: $real-estate-teaser-slider-control-icon-size;
  height: $real-estate-teaser-slider-control-icon-size;
  fill: currentColor;
}
