body {
  @include copy-text();
  color: $dwg-color-text;
  font-family: Asap, Arial, sans-serif;
}

// Headings

h1,
.heading-1 {
  @include heading-1();
}

h2,
.heading-2 {
  @include heading-2();
}

h3,
.heading-3 {
  @include heading-3();
}

// Copytext

.intro {
  @include intro-text();
}

// Links, Buttons, CTAs
a:not([class]),
a.mail,
a.copy-link {
  @include copy-link();
}

.cta {
  @include cta();
}

.cta-rounded,
.cta-ghost {
  --cta-size: 40px;
  --cta-icon-size: 16px;
  --cta-font-size: 16px;
  --cta-color: #{$dwg-color-interaction-primary};
  --cta-color-active: #{$dwg-color-interaction-primary-active};
  --cta-color-contrast: #{$dwg-white};
  --cta-color-background: var(--cta-color);

  display: inline-flex;
  align-items: center;
  padding: 1em calc(var(--cta-size) / 2);
  gap: var(--cta-icon-size);

  background-color: var(--cta-color-background);
  border: 1px solid var(--cta-color-background);
  border-radius: var(--cta-size);

  color: var(--cta-color-contrast);
  font-size: var(--cta-font-size);
  line-height: 1.2;
  text-decoration: none;
  text-transform: uppercase;

  cursor: pointer;

  /* stylelint-disable plugin/no-low-performance-animation-properties */
  transition: background-color $transition-default;

  @include mq-min-width($bp-layout-scale) {
    --cta-size: 60px;
    --cta-icon-size: 23px;
    --cta-font-size: 18px;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: var(--cta-color-active);
  }

  svg {
    display: block;
    width: var(--cta-icon-size);
    height: var(--cta-icon-size);
    fill: currentColor;
  }
}

.cta-ghost {
  background-color: transparent;
  border-color: currentColor;

  color: var(--cta-color);

  transition: color $transition-default;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;

    color: var(--cta-color-active);
  }
}

.cta-big {
  @include mq-min-width($bp-typography) {
    --cta-size: 74px;
    --cta-icon-size: 28px;
    font-size: 21px;
  }
}

.cta-secondary {
  --cta-background-color: #{$dwg-color-interaction-secondary};
  --cta-background-color-active: #{$dwg-color-interaction-secondary-active};
  --cta-text-color: #{$dwg-white};
  --cta-text-color-active: #{$dwg-white};
  --cta-color: #{$dwg-color-interaction-secondary};
  --cta-color-active: #{$dwg-color-interaction-secondary-active};
}

.cta-icon-only {
  justify-content: center;
  width: var(--cta-size);
  height: var(--cta-size);
  padding: 0;

  border-radius: 50%;

  @include mq-max-width($bp-layout-scale) {
    --cta-size: 44px;
    --cta-icon-size: 20px;
  }
}
