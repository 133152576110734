.background-section {
  position: relative;

  background-color: $dwg-color-background-light;
}

.background-section-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  opacity: 0.6;
}

.background-section-content {
  position: relative;
  margin: $spacing-40 0;
}
