.repair-form {
  @include card();
  @include responsive-layout-property(padding, $spacing-20, $spacing-40);

  padding-top: $spacing-40;

  .form {
    display: grid;

    @include mq-min-width($bp-grid) {
      grid-column-gap: $spacing-40;
      grid-template-columns: 1fr 1fr;

      .form-field[id='form-field-description'] {
        grid-row: span 2;
      }

      .form-field[id='form-field-checkDataProtection'],
      .buttons-container {
        grid-row: 7;
        grid-column: 2;
      }

      .buttons-container {
        justify-self: end;
      }
    }

    .mat-raised-button {
      @include cta();
    }

    .mat-button-wrapper {
      font-family: Asap, Arial, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
    }

    .mat-error {
      font-size: 16px;
    }

    /* stylelint-disable-next-line selector-max-compound-selectors */
    .mat-checkbox-checked .mat-checkbox-background {
      background-color: $dwg-color-primary;
    }
  }
}
